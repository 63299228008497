import { contactSubmit } from './contact.js';
import { navToggle } from './header.js';
import { helpToggle } from './help.js';
import { maps } from './map.js';
import { reviews } from './reviews.js';
import { unitFilters, facilityTabs, facilityCaro, unitModal, facilityMap } from './facility.js';

navToggle.init();

helpToggle.init();

maps.init();

reviews.init();

unitFilters.init();

facilityTabs.init();
facilityCaro.init();
facilityMap.init();

if(document.getElementById('unit_modal')){
	unitModal.init();
}

if (document.getElementById('contact_form')) {
	contactSubmit();
}
