import {
	AutomatitCarousel
} from './automatit_carousel.m';
import {
	getMapsHelper
} from './util/mapsHelper';

export const unitFilters = (function(){
	let my = {},
		size = null;

	function _filterUnits(){
		let noUnits = document.getElementById('no_units');
		noUnits.style.display = "block";
		[].forEach.call(document.querySelectorAll('#unit_table .unit_row'), (el) => {
			if(size === null){
				el.style.display = 'table-row';
				noUnits.style.display = "none";
			}else{
				let props = el.dataset.prop;
				el.style.display = 'none';
				if(props.includes(size)){
					el.style.display = 'table-row';
					noUnits.style.display = "none";
				}
			}
		});
	}

	function _toggleFilters({currentTarget}){
		if(currentTarget.dataset.filter === "all"){
			[].forEach.call(document.querySelectorAll('#facility_filters .filter'), (el) => {
				el.classList.remove("active");
			});
			size = null;
			currentTarget.classList.add('active');
		}else{
			let active = currentTarget.parentElement.querySelector('.active');
			active.classList.remove('active');
			currentTarget.classList.add('active');
			size = currentTarget.dataset.filter;
		}

		_filterUnits();
	}

	function _assignListeners(){
		[].forEach.call(document.querySelectorAll('#facility_filters .filter'), (el) => {
			el.addEventListener('click', _toggleFilters);
		});
	}

	my.init = () => {
		if(document.getElementById('facility_size_filters')){
			_assignListeners();
		}
	};

	return my;
})();

export const facilityTabs = (function(){
	let my = {};

	function _toggleTabs({ currentTarget }) {
		[].forEach.call(document.querySelectorAll('#facility_nav .btn'), (el) => {
			el.classList.remove('active');
		});

		currentTarget.classList.add('active');

		[].forEach.call(document.querySelectorAll('.facility_tab'), (el) => {
			el.classList.remove('active');
		});

		const targetElement = document.getElementById(currentTarget.dataset.target);
		targetElement.classList.add('active');

		// Hide #facility_filters if the clicked tab is not facility_rates
		const facilityFilters = document.getElementById('facility_filters');
		if (currentTarget.dataset.target !== 'facility_rates') {
			facilityFilters.style.display = 'none';
		} else {
			facilityFilters.style.display = 'block';
		}
	}

	function _assignListeners() {
		[].forEach.call(document.querySelectorAll('#facility_nav .btn'), (el) => {
			el.addEventListener('click', _toggleTabs);
		});
	}

	my.init = () => {
		if (document.getElementById('facility_nav')) {
			_assignListeners();
		}
	};

	return my;
})();

export const facilityCaro = (function () {
	let my = {};

	my.init = function () {
		if (document.getElementById('facility_caro')) {

			if (window.slide_images.length > 1) {
				const options = {
					element: document.getElementById('facility_caro'),
					speed: 600,
					images: window.slide_images,
					intervalTiming: 6000,
					imagesAsBackgrounds: true,
					showDots: true,
					useCheverons: false
				};
				AutomatitCarousel(options);
			} else {
				let btns = document.getElementById('facility_caro_chevrons');
				btns.parentNode.removeChild(btns);
				const options = {
					element: document.getElementById('facility_caro'),
					speed: 600,
					images: window.slide_images,
					imagesAsBackgrounds: true,
					intervalTiming: 0,
					showDots: false
				};
				AutomatitCarousel(options);
			}
		}
	};

	return my;
})();

export const unitModal = (function(){
	let my = {};

	function _unitModal(){
		const modal = document.getElementById('unit_modal');
		const closeModalButton = document.getElementById('unit_modal_close');
		let rentButton = document.getElementById('unit_modal_rent');
		let resButton = document.getElementById('unit_modal_res');
		const buttons = document.querySelectorAll('#unit_table .continue');
		modal.addEventListener('click', function(e){
			let clickedModal = e.target.closest('div');
			if(clickedModal.getAttribute('data-container') && clickedModal.getAttribute('data-container') === 'modal-overlay'){
				modal.toggleAttribute('open');
				document.body.classList.toggle('no_overflow');
			}
		});
		closeModalButton.addEventListener('click', function(){
			modal.toggleAttribute('open');
			document.body.classList.toggle('no_overflow');
		});
		buttons.forEach(button => {
			button.addEventListener('click', function(e){
				let clickedUnit = e.currentTarget;
				rentButton.setAttribute('href', clickedUnit.getAttribute('data-rent'));
				resButton.setAttribute('href', clickedUnit.getAttribute('data-res'));
				modal.toggleAttribute('open');
				document.body.classList.toggle('no_overflow');
			});
		});
	}

	my.init = function(){
		_unitModal();
	};

	return my;
})();

/* eslint-disable no-undef */
export const facilityMap = (function(){
	let my = {},
		map = document.getElementById('facility_map');

	my.init = function(){
		if(document.getElementById('facility_map')){
			const mapHelper = getMapsHelper();
			mapHelper.ready()
				.then(() => {
					const theMap = mapHelper.createMap({
						element: map,
						locationElementSelector: '#facility_map',
						useRichmarker: true,
						markerReducer: el => {
							return {
								lat: el.getAttribute('lat'),
								lng: el.getAttribute('lng'),
								content: `
                        <span class="map_pin">
                            <svg xmlns="http://www.w3.org/2000/svg" width="43.8" height="65.65" viewBox="0 0 43.8 65.65"><g><path d="M21.9,0A21.89,21.89,0,0,0,3.54,33.83L21.9,65.65,40.27,33.83A21.91,21.91,0,0,0,21.9,0"/></g></svg>
                        </span>
                        `
							};
						}
					});

					google.maps.event.addListenerOnce(theMap, 'idle', () => {
						theMap.setZoom(15);
					});
				});
			
			[].forEach.call(document.querySelectorAll('#facility_caro_toggles .toggle'), (btn) => {
				btn.addEventListener('click', ({currentTarget}) => {
					[].forEach.call(document.querySelectorAll('#facility_caro_toggles .toggle'), (el) => {
						el.classList.remove('active');
					});
					currentTarget.classList.add('active');

					map.classList.remove('active');
					if(currentTarget.classList.contains('map')){
						map.classList.add('active');
					}
				});
			});
		}
	};

	return my;
})();
